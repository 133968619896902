<template>
  <div class="row">
    <div
      class="col-md-3"
      v-for="(item, index) in items"
      :key="index"
      v-on:click="viewSingle(item)"
    >
      <div class="grid-images imagesgridesec">
        <img
          :src="'http://www.justacar.in/lap' + item.photos[0].url"
          :alt="item.make"
        />
        <div class="banner-text-brand bg-add">
          <div class="banner-text-iner">
            <h4>
              {{
                item.make.toUpperCase() +
                " " +
                item.model +
                " " +
                item.modelvariant
              }}
            </h4>
            <span>{{ new Date(item.mfgyear).getFullYear() }}</span>
             <h5 class="diesel-sec">{{ item.fueltype | fuelType }}</h5>
          </div>
          <div class="banner-text-right">
           
            <span class="price" v-if="item.price">&#8377;{{item.price}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Singlegrid",
  props: {
    items: Array,
    viewSingle: Function,
  },
};
</script>