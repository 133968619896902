<template>
  <section class="popular-brans-sec" v-if="popular_cars.length > 0">
    <div class="container">
      <div class="col-md-4">
        <div class="first-block padd-block">
          <h3>Latest</h3>
          <vue-slick-carousel class="two-slider" v-bind="twosliderSettings">
            <div
              v-for="(car, index) in popular_cars"
              :key="index"
              v-on:click="viewSingle(car)"
            >
              <div class="col-md-12">
                <div class="slider-image brand-first imagesize">
                  <img
                    :src="'http://www.justacar.in/lap' + car.photos[0].url"
                    class="d-inline-block align-top"
                    :alt="car.make"
                  />
                  <div class="banner-text-brand">
                    <div class="banner-text-iner">
                      <h4>
                        {{
                          car.make.toUpperCase() +
                          " " +
                          car.model +
                          " " +
                          car.modelvariant
                        }}
                      </h4>
                      <span>{{ new Date(car.mfgyear).getFullYear() }}</span>
                    </div>
                    <div class="banner-text-right addprice">
                      <h5 class="diesel-sec">
                        {{ car.fueltype | fuelType }}
                      </h5>
                      <span class="price" v-if="car.price"
                        >&#8377;{{ car.price }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-slick-carousel>
        </div>
      </div>

      <div class="col-md-4">
        <div class="first-block padd-block">
          <h3>Services</h3>
          <vue-slick-carousel class="two-slider" v-bind="twosliderSettings">
            <div v-for="(service, index) in services" :key="index">
              <div class="col-md-12">
                <div class="slider-image brand-first">
                  <img
                    :src="service.image"
                    class="d-inline-block align-top"
                    :alt="service.name"
                  />
                  <div class="banner-text-brand">
                    <div class="banner-text-iner text-change">
                      <h4>{{ service.name }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
      <div class="col-md-4" v-if="showtweets">
        <div class="first-block sec-sllider-brands" id="twitter-block">
          <h3>
            <a
              class="twitter-timeline"
              href="https://twitter.com/JustACar_in?ref_src=twsrc%5Etfw"
              >Tweets by JustACar_in</a
            >
          </h3>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
const twttr = window.twttr;
export default {
  name: "Latest",
  components: { VueSlickCarousel },
  props: {
    msg: String,
    brands: Array,
    services: Array,
    popular_cars: Array,
    viewSingle: Function,
  },
  mounted() {
    setTimeout(() => {
      twttr.widgets.load();
      this.showtweets = true;
    }, 1000);
  },
  data() {
    return {
      showtweets: false,
      twosliderSettings: {
        dots: true,
        arrow: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 2,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      // brandsliderSettings: {
      //   dots: true,
      //   arrow: false,
      //   autoplay: true,
      //   speed: 300,
      //   slidesToShow: 2,
      //   rows: 2,
      //   infinite: true,
      //   prevArrow:
      //     '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
      //   nextArrow:
      //     '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
      //   variableWidth: true,
      //   responsive: [
      //     {
      //       breakpoint: 1024,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 2,
      //         infinite: true,
      //         dots: true,
      //       },
      //     },
      //     {
      //       breakpoint: 600,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 2,
      //       },
      //     },
      //     {
      //       breakpoint: 480,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1,
      //       },
      //     },
      //   ],
      // },
    };
  },
};
</script>