<template>
  <div>
    <filters
      :payload="payload"
      :page="page"
      :searchItems="searchItems"
      :colors="colors"
    />
    <results
      :items="items"
      :hasMore="hasMore"
      :page="page"
      :searchItems="searchItems"
      :changePage="changePage"
    />
  </div>
</template>


<script>
import Axios from "axios";
import Filters from "./sections/search-results/Filters.vue";
import Results from "./sections/search-results/Results.vue";
export default {
  name: "Searchresults",
  components: {
    Filters,
    Results,
  },
  data() {
    return {
      items: [],
      hasMore: false,
      page: 1,
      payload: JSON.parse(sessionStorage.getItem("search_payload")),
      colors:[]
    };
  },
  mounted() {
    this.searchItems();
  },
  methods: {
    changePage: function (type = "next") {
      if (type === "next") {
        this.page = this.page + 1;
      }
      if (type === "back") {
        if (this.page > 1) {
          this.page = this.page - 1;
        }
      }
      this.searchItems();
    },
    searchItems: function () {
      var data = JSON.parse(sessionStorage.getItem("search_payload"));
      data.PageNo = this.page;
      Axios.post("http://www.justacar.in/lap/api/Vehicle/Search", data).then(
        (res) => {
          console.log(res);
          this.items = res.data.body.items;
          this.hasMore = res.data.body.HasMore;
          this.page = res.data.body.page;
          this.items.forEach((val) => {
            if (!this.colors.includes(val.color)) {
              this.colors.push(val.color);
            }
          });
        }
      );
    },
  },
};
</script>
