<template>
  <div class="row">
    <div
      class="col-md-12"
      v-for="(item, index) of items"
      :key="index"
      v-on:click="viewSingle(item)"
    >
      <div class="">
        <div class="mainlist-sec">
          <div class="col-md-9">
            <div class="listing-image">
              <img
                :src="'http://www.justacar.in/lap' + item.photos[0].url"
                :alt="item.make"
              />
            </div>
            <div
              class="product-details list-details"
              style="cursor: pointer"
            >
              <h4>{{
                item.make.toUpperCase() +
                " " +
                item.model +
                " " +
                item.modelvariant
              }}</h4>
              <!-- <p>
                Keyless start, Leather seats. Navigation System. Reversing
                Camera.
              </p> -->
              <div class="name-sec cardate">
                <span>{{ new Date(item.mfgyear).getFullYear() }}</span>
                <span>{{ item.fueltype | fuelType }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="prices-sec">
              <h4 v-if="item.price">&#8377;{{item.price}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Singlelist",
  props: {
    items: Array,
    viewSingle: Function,
  },
  methods: {
    gotoSingle() {
      this.$router.push("/details");
    },
  },
};
</script>