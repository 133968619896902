<template>
  <div>
    <section class="about-us-sec buyacar_bg">
      <div class="container">
        <div class="col-md-12">
          <div class="sell-car-text text-change-usedcar">
            <span>Are You Looking</span>
            <h3>TO BUY A CAR?</h3>
            <div class="sell-car-btn">
              <a href="javascript:void(0)" @click="onClose(true)"
                >search your car</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <Homesearch :brands="brands" />

    <section class="shop-by-brand">
      <div class="container">
        <div class="row">
          <h3>Shop by brand</h3>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="brand-list">
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="searchQuerym('maruti-suzuki')"
                  >
                    <img src="images/Maruti.png" class="default-img" atl="" />

                    <p>Maruti</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('hyundai')">
                    <img src="images/hyndai.png" class="default-img" atl="" />

                    <p>Hyndai</p>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="searchQuerym('mahindra')"
                  >
                    <img
                      src="images/mahindra-car.png"
                      class="default-img"
                      atl=""
                    />

                    <p>Mahindra</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('toyota')">
                    <img src="images/toyota.png" class="default-img" atl="" />

                    <p>Toyota</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('honda')">
                    <img
                      src="images/honda-car.png"
                      class="default-img"
                      atl=""
                    />

                    <p>Honda</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="schedule-your-free" id="get-in-touch">
      <div class="container">
        <div class="col-md-12">
          <div class="col-md-7 maine-scde-sec">
            <div class="schedule-inner-sec">
              <p>Get in Touch</p>

              <h3>Send Us Message</h3>

              <div class="form-start-help">
                <div class="col-md-6">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="sel1"
                      v-model="localInquiryType"
                    >
                      <option value="">Select Query</option>
                      <option value="VHL">Car Loan</option>
                      <option value="Used Car Purchase">
                        Used Car Purchase
                      </option>
                      <option value="Used Car Sale">Used Car Sale</option>
                      <option value="Insurance">Insurance</option>
                      <option value="Home Loan">Home Loan</option>
                      <option value="Loan Against Property">
                        Loan Against Property
                      </option>
                    </select>
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div
                    class="form-group"
                    style="display: flex; gap: 5px; margin-top: 6px"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      v-model="FirstName"
                      style="padding-left: 10px; padding-right: 80px"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="LastName"
                    />
                   
                    <span
                      class="user-iconss"
                      style="position: absolute; left: 10px; top:15px; color: #348aff"
                    >
                    <i class="fa fa-user" aria-hidden="true"></i>
                    </span>

                  </div>
                </div> -->

                <div class="col-md-6">
                  <div
                    class="form-group"
                    style="
                      display: flex;
                      gap: 5px;
                      margin-top: 0px;
                      position: relative;
                    "
                  >
                    <!-- First Name Input -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      v-model="FirstName"
                      style="padding-left: 26px; flex: 1"
                    />
                    <!-- Last Name Input -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="LastName"
                      style="padding-left: 26px; flex: 1"
                    />
                    <!-- User Icon -->
                    <span
                      class="user-iconss"
                      style="
                        position: absolute;
                        left: 10px;
                        top: 9px;
                        color: #348aff;
                      "
                    >
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email"
                      v-model="Email"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-envelope" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group mobile-input-wrapper">
                    
                    <span class="user_icons"
                      ><i class="fa fa-mobile" aria-hidden="true"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Number"
                      v-model="PhoneNo"
                      @input="validatePhoneNo"
                      :class="{ 'is-valid': isVerified }"
                    />
                  

                    <span
                      v-if="isVerified"
                      class="tick-icon"
                      style="
                        position: absolute;
                        right: 110px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: none;
                      "
                      >✔️</span
                    >
                    <button
                      class="verify-btn"
                      v-if="showVerifyButton"
                      @click="verifyMagicLink"
                      :disabled="isVerified"
                    >
                      Verify
                    </button>
                  </div>
                </div> -->

                <div class="col-md-6">
                  <div
                    class="form-group mobile-input-wrapper"
                    style="
                      position: relative;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <!-- Mobile Icon -->
                    <span
                      class="user-icons"
                      style="position: absolute; left: 10px; color: #348aff"
                    >
                      <i class="fa fa-mobile" aria-hidden="true"></i>
                    </span>

                    <!-- Input Field -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Number"
                      v-model="PhoneNo"
                      @input="validatePhoneNo"
                      :class="{ 'is-valid': isVerified }"
                      style="padding-left: 26px; padding-right: 80px"
                    />

                    <!-- Verify Button Inside Field -->
                    <button
                      class="verify-btnss"
                      v-if="showVerifyButton"
                      @click="verifyMagicLink"
                      :disabled="isVerified"
                    >
                      Verify
                    </button>

                    <!-- Tick Icon (Verified Checkmark) -->
                    <span
                      v-if="isVerified"
                      class="tick-icon"
                      style="
                        position: absolute;
                        right: 85px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: green;
                        display: none;
                      "
                    >
                      ✔️
                    </span>
                  </div>
                </div>

                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City you live in"
                      v-model="City"
                     style="padding-left: 26px"
                    />

                    <span
      class="city-icon"
      style="position: absolute; left: 10px; top: 9px; color: #348aff"
    >
      <i class="fa fa-map-marker" aria-hidden="true"></i>
    </span>
                  </div>
                </div>  -->

                <div class="col-md-12">
                  <div
                    class="form-group"
                    style="position: relative; margin-top: 6px"
                  >
                    <!-- City Input -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City you live in"
                      v-model="City"
                      style="padding-left: 26px"
                    />
                    <!-- City Icon -->
                    <span
                      class="city-icon"
                      style="
                        position: absolute;
                        left: 10px;
                        top: 9px;
                        color: #348aff;
                      "
                    >
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>

                <!-- <div class="col-md-12">
                  <div class="btn-section">
                    <button
                      type="button"
                      class="btn sumbit-btn"
                      :class="{ 'blurred-button': !isVerified }"
                      @mouseover="showMessage"
                      @mouseleave="hideMessage"
                      @click="submitQuery"
                    >
                      Submit
                    </button>
                    <div
                      v-if="showTooltip && !isVerified"
                      class="tooltip-message"
                      :style="{
                        top: tooltipPositionTop + 'px',
                        right: tooltipPositionLeft + 'px',
                      }"
                    >
                      Please verify your whatsapp number.
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showOtpModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <span class="close" @click="showOtpModal = false">&times;</span>
            <div>
              <h3 style="color: #000000; text-align: center">
                OTP Verification
              </h3>
            </div>
            <div>
              <p v-if="modalMessage">{{ modalMessage }}</p>

              <div
                class="otp-inputs"
                style="margin-top: 10px; display: flex; gap: 15px"
              >
                <input
                  v-for="(input, index) in otpInputs"
                  :key="index"
                  type="text"
                  v-model="otpInputs[index]"
                  maxlength="1"
                  class="otp-input"
                  :style="inputStyle(index)"
                  @input="handleInput(index)"
                  @keydown="handleKeydown(index)"
                  ref="otpInput"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="resend-btn"
                :disabled="timer > 0"
                @click="resendOtp"
              >
                {{ timer > 0 ? `Resend Code in ${timer}s` : "Resend Code" }}
              </button>
              <button class="verify-btns" @click="verifyOtp">Verify</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="Used Car Purchase"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
import Homesearch from "./sections/Homesearch";

import axios from "axios";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
export default {
  name: "Byusedcar",
  components: { Inquiryformmodal, Homesearch },
  data() {
    return {
      brands: [],
      showModal: false,
      localInquiryType: "Used Car Purchase",
      isVerified: false,
      showVerifyButton: true,
      otpInputs: ["", "", "", ""],
      otpCode: [],
      timer: 60,
      timerInterval: null,
      showOtpModal: false,
      PhoneNo: "",
      City: "",
      FirstName: "",
      LastName: "",
      Email: "",
      showTooltip: false,
      VUE_APP_ROOT_API: process.env.VUE_APP_ROOT_API,
      otpVerified : false
    };
  },

  methods: {
    onClose: function (status) {
      this.showModal = status;
    },

    // verifyMagicLink() {
    //   if (this.localInquiryType === "") {
    //       alert("Please select query.");
    //       return false;
    //     }
    //     if (this.FirstName === "" || this.LastName === "") {
    //       alert("Please enter your full name.");
    //       return false;
    //     }
    //     if (this.Email != "") {
    //       if (!this.validateEmail()) {
    //         alert("Please enter a valid email address with a domain name.");
    //         return false;
    //       }
    //     }
    //     if (this.PhoneNo === "") {
    //       alert("Please enter mobile number.");
    //       return false;
    //     }
    //     if (!this.PhoneNo || this.PhoneNo.length < 10) {
    //     alert("Please enter a valid phone number."); // Notify user if invalid
    //     return;
    //     }
    //   this.otpInputs = Array(4).fill("");  
    //   this.enteredOtp = "";
    //   let data = {
    //     phoneNumber: "+91" + this.PhoneNo,
    //     expiry: 120,
    //     otpLength: 4,
    //     channels: ["WHATSAPP"],
    //   };

    //   this.showOtpModal = true; // Show the OTP modal
    //   this.startTimer(); // Start the timer for OTP resend
    //   // Here, you might add your logic to send the OTP to the phone number
    //   console.log("hello--",data);
      
    //   axios
    //     .post("https://auth.otplessss.app/auth/v1/initiate/otp", data, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         clientId: "QH19JS2GMJL06ABDLY24I1VHE1ZJZIAK",
    //         clientSecret: "psa9q89xk27p6y7vx5ru4ntrm6yb2j3t",
    //       },
    //     })
    //     .then((res) => {
    //       this.isVerified = true;
    //       this.showTooltip = false;
    //       console.log("hello");
          
    //       if (res.data.requestId) {
    //         this.requestId = res.data.requestId;
    //         this.showSubmitButton = true;
    //         this.showVerifyButton = false;

    //         Toastify({
    //           text: "OTP sent successfully.",
    //           duration: 3000, // Customize duration as needed
    //           close: true,
    //           gravity: "top", // Position on the screen
    //           position: "right", // Position on the screen
    //           backgroundColor: "#6ebe44", // Success color
    //         }).showToast();
    //       } else {
    //         alert(res.data.message);
    //       }
    //     })
    //     .catch((err) => {
    //       this.resetForm();
    //       console.log("Error:", err);
    //       console.log("Error:", err.response ? err.response.data : err);
    //       alert("There was an error to verify whatsapp.");
    //     });
    // },
    verifyMagicLink() {
        if (this.FirstName === "" || this.LastName === "") {
          alert("Please enter your full name.");
          return false;
        }
       
        if (this.Email != "") {
          if (!this.validateEmail()) {
            alert("Please enter a valid email address with a domain name.");
            return false;
          }
        }
        if (this.PhoneNo === "") {
          alert("Please enter mobile number.");
          return false;
        }
      if (!this.PhoneNo || this.PhoneNo.length < 10) {
        alert("Please enter a valid phone number."); // Notify user if invalid
        return;
      }

      this.otpInputs = Array(4).fill("");  
      this.enteredOtp = "";
      this.showOtpModal = true; 
       
      let data = {
        phoneNumber: "+91" + this.PhoneNo,
        expiry: 120,
        otpLength: 4,
        channels: ["WHATSAPP"],
      };
      this.startTimer(); 
      
      axios
        .post("https://auth.otpless.app/auth/v1/initiate/otp", data, {
          headers: {
            "Content-Type": "application/json",
            clientId: "QH19JS2GMJL06ABDLY24I1VHE1ZJZIAK",
            clientSecret: "psa9q89xk27p6y7vx5ru4ntrm6yb2j3t",
          },
        })
        .then((res) => {
       
         // this.isVerified = true;
          this.showTooltip = false;
          if (res.data.requestId) {
            this.requestId = res.data.requestId;
            this.showVerifyButton = false;

            Toastify({
              text: "OTP sent successfully.",
              duration: 3000, // Customize duration as needed
              close: true,
              gravity: "top", // Position on the screen
              position: "right", // Position on the screen
              backgroundColor: "#6ebe44", // Success color
            }).showToast();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          this.resetForm();
          console.log("Error:", err);
          console.log("Error:", err.response ? err.response.data : err);
          alert("There was an error submitting the form.");
        });
        this.enteredOtp = "";    
    },



    verifyOtp() {
      const enteredOtp = this.otpInputs.join("");
      axios
        .post(
          "https://auth.otpless.app/auth/v1/verify/otp",
          {
            requestId: this.requestId,
            otp: enteredOtp,
          },
          {
            headers: {
              "Content-Type": "application/json",
              clientId: "QH19JS2GMJL06ABDLY24I1VHE1ZJZIAK",
              clientSecret: "psa9q89xk27p6y7vx5ru4ntrm6yb2j3t",
            },
          }
        )
        .then((res) => {
          this.isVerified = true;
          this.showTooltip = false;
          if (res.data.isOTPVerified) {
            this.isVerified = res.data.isOTPVerified;
            this.submitQuery();
            Toastify({
              text: res.data.message,
              duration: 3000,
              backgroundColor: "#6ebe44",
              close: true,
            }).showToast();
            this.showOtpModal = false;
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          this.resetForm();
          console.log("Error:", err);
          alert("There was an error submitting the form.");
        });
       
    },
    startTimer() {
      this.timer = 60; // Reset timer to 60 seconds
      clearInterval(this.timerInterval); // Clear any existing timer

      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    resendOtp() {
      this.verifyMagicLink(); // Resend OTP
    },
    handleInput(index) {
      // Move to the next input if the current one is filled
      if (
        this.otpInputs[index].length === 1 &&
        index < this.otpInputs.length - 1
      ) {
        this.$refs.otpInput[index + 1].focus(); // Focus next input
      }
    },

    inputStyle(index) {
      return this.otpInputs[index] ? { outline: "2px solid #6ebe44" } : {};
    },
    validatePhoneNo() {
      this.PhoneNo = this.PhoneNo.replace(/\D/g, "");
      if (this.PhoneNo.length > 10) {
        this.PhoneNo = this.PhoneNo.slice(0, 10);
      }
    },
    submitQuery() {
      //if (this.isVerified) {
        // if (this.localInquiryType === "") {
        //   alert("Please select query.");
        //   return false;
        // }
        // if (this.FirstName === "" || this.LastName === "") {
        //   alert("Please enter your full name.");
        //   return false;
        // }
        // if (this.Email != "") {
        //   if (!this.validateEmail()) {
        //     alert("Please enter a valid email address with a domain name.");
        //     return false;
        //   }
        // }
        // if (!this.validateEmail() && this.Email != "") {
        //   alert("Please enter a valid email address with a domain name.");
        //   return false;
        // }
        // if (this.PhoneNo === "") {
        //   alert("Please enter mobile number.");
        //   return false;
        // }
        const formData = new FormData();
        formData.append(
          "inquiryData",
          JSON.stringify({
            firstName: this.FirstName,
            lastName: this.LastName,
            emailAddress: this.Email,
            inquiryStatus: `Open`,
            inquiryType: this.localInquiryType,
            contactNumber: `${this.PhoneNo}`,
            city: this.City,
          })
        );
        for (const [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }
        axios
          .post(`${this.VUE_APP_ROOT_API}/add-inquiry1`, formData)
          .then((res) => {
            this.baseResponse = res.data;
            
            if (
              this.baseResponse === "" ||
              this.baseResponse.code === "ADDED"
            ) {
              alert("Inquiry Request submitted successfully. Our Support Team Will Contact You Soon!");
              this.resetForms();
            } else {
              this.resetForms();
              alert("Some Thing went wrong! please try later.");
            }
          })
          .catch((err) => {
            this.resetForms();
            console.log("Error:", err);
            console.log("Error:", err.response ? err.response.data : err);
            alert("There was an error submitting the form.");
          });
      //}
    },
    resetForms() {
      this.isVerified = false;
      this.showTooltip = true;
      this.showSubmitButton = false;
      this.showVerifyButton = true;
      this.FirstName = undefined;
      this.LastName = undefined;
      this.Email = undefined;
      this.PhoneNo = undefined;
      this.City = undefined;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.Email);
    },

    showMessage() {
      this.showTooltip = true;

      // Position the tooltip near the button

      this.tooltipPositionTop = -250;
      this.tooltipPositionLeft = 5;
    },
    hideMessage() {
      this.showTooltip = false;
    },
    searchQuerym: function (make) {
      var data = {
        MakeId: make,
        FuelType: null,
        TransType: null,
        OrderBy: "1",
        OrderAsc: false,
        PageNo: 1,
        PageSize: 36,
        ValueStart: 0,
        ValueEnd: 20000000,
        model: null,
      };
      sessionStorage.setItem("search_payload", JSON.stringify(data));
      this.$router.push("/search");
    },
  },
 mounted() {
    axios
      .get(`http://www.justacar.in/lap/api/Vehicle/PopularBrands`)
      .then((res) => {
        var brands = [];
        res.data.body.items.forEach((br) => {
          var x = {
            name: br,
            logo: `images/${br}.png`,
            logobig: `images/brand-big/${br}.png`,
          };
          brands.push(x);
        });
        this.brands = brands;
      });
  },
};
</script>
<style>

.maine-scde-sec select {
  padding: 3px 6px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 600;
  height: 37px;
  border-radius: 4px;
  margin-top: 7px;
}


.schedule-inner-sec input#phone {
  padding: 3px 37px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  height: 37px;
  width: 100%;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mobile-input-wrapper {
  position: relative;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
}

.mobile-input-wrapper input {
  flex: 1; /* Allow the input field to take available space */
  padding: 5px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

.mobile-input-wrapper .verify-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #6ebe44;
  color: white;
  border: none;
  padding: 6px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.mobile-input-wrapper .verify-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-btn:hover {
  background-color: #0069d9;
}

.submit-btn {
  background-color: #6ebe44;
  border: none;
  color: white;
  padding: 6px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.mobile-input-wrapper .verify-btnss {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #6ebe44;
  color: white;
  border: none;
  padding: 6px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.verify-btn {
  background-color: #6ebe44;
  border: none;
  color: white;
  padding: 6px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-btns {
  background-color: #6ebe44;
  border: none;
  color: white;
  padding: 6px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.blurred-button {
  opacity: 2;
  cursor: not-allowed;
}
.tooltip-message {
  background-color: #ffcc00;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  font-size: 14px;
  z-index: 10;
  visibility: visible;
  white-space: nowrap; /* Prevent text wrapping */
  max-width: 300px; /* Adjust max width as needed */
  word-wrap: break-word; /* Ensure the text breaks properly if it exceeds max width */
}

.user-icons i {
  font-size: 22px !important;
}
</style>
