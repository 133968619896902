<template>
  <section class="nav-slider" id="navtabs">
    <div class="container" v-if="popular_brands.length > 0">
      <div class="row">
        <div class="col-md-4">
          <h4 class="popularcar">Popular Car</h4>
        </div>
        <div class="col-md-8">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li
              v-for="(br, index) in popular_brands"
              :key="index"
              :class="br === brand_tab ? 'nav-item show' : 'nav-item'"
            >
              <a
                class="nav-link"
                id="audi"
                href="#home"
                aria-controls="home"
                aria-selected="true"
                v-on:click="brandsTab(br)"
                >{{ br.toUpperCase() }}
                <span>{{ brcounts[index] }} Vehicles </span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane in fade active"
          id="audi"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
        <div class="col-md-12 popular-cars">
          <VueSlickCarousel class="your-class" v-bind="popularcarssettings">
            
              <div
                class="slider-image"
                v-for="(car, index) in brandwise[brand_tab]"
                :key="index"
                v-on:click="viewSingle(car)"
              >
               <div class="col-md-12">
                <img
                  :src="'http://www.justacar.in/lap' + car.photos[0].url"
                  class="d-inline-block align-top"
                  alt=""
                />
                <div class="banner-text-brand bg-add">
                  <div class="banner-text-iner">
                    <h4>
                      {{
                        car.make.toUpperCase() +
                        " " +
                        car.model +
                        " " +
                        car.modelvariant
                      }}
                    </h4>
                    <span>{{ new Date(car.mfgyear).getFullYear() }}</span>
                      <h5 class="diesel-sec">{{ car.fueltype | fuelType }}</h5>
                  </div>
                  <div class="banner-text-right">
                  
                    <span class="price" v-if="car.price">&#8377;{{car.price}}</span>
                  </div>
                </div>
              </div>
           </div>
          </VueSlickCarousel>
           </div>
        </div>
        <!-- <div class="view-more">
          <a href="javascript:void(0)">View All</a>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Popularcars",
  components: { VueSlickCarousel },
  props: {
    msg: String,
    popular_cars: Array,
    viewSingle:Function
  },
  data() {
    return {
      popular_brands: [],
      brcounts: [],
      brandwise: [],
      popularcarssettings: {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
        arrow: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    brandsTab: function (brand) {
      this.brand_tab = brand;
      this.filterCars(brand);
      console.log("ok", brand);
    },
    filterCars: function (selectbrand = null) {
      console.log(this.cBrands);
      var brands = [];
      var ubrands = [];
      var brandwise = [];
      this.popular_cars.forEach((val) => {
        if (!ubrands.includes(val.make)) {
          if (ubrands.length <= 3) {
            brandwise[val.make] = [val];
            ubrands.push(val.make);
            var k = ubrands.indexOf(val.make);
            brands[k] = 1;
          }
        } else {
          brandwise[val.make].push(val);
          var kk = ubrands.indexOf(val.make);
          if (kk >= 0) {
            brands[kk]++;
          }
        }
      });
      console.log(brands, ubrands);
      this.popular_brands = ubrands;
      this.brcounts = brands;
      this.brandwise = brandwise;
      this.brand_tab = selectbrand ? selectbrand : ubrands[0];
      console.log("ok", this.brandwise[this.brand_tab], this.brand_tab);
    },
  },
  mounted() {
    setTimeout(() => {
      this.filterCars();
    }, 1000);
  },
};
</script>