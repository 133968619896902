<template>
  <div class="modal-content">
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Inquiry Form</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="onClose(false)"
                      >&times;</span
                    >
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="querytype"
                      v-model="InquiryType"
                    >
                      <option value="">Select Query</option>
                      <option value="VHL">Car Loan</option>
                      <option value="Used Car Purchase">
                        Used Car Purchase
                      </option>
                      <option value="Used Car Sale">Used Car Sale</option>
                      <option value="Insurance">Insurance</option>
                      <option value="HML">Home Loan</option>
                      <option value="LAP">Loan Against Property</option>
                    </select>
                  </div>

                  <div
                    class="form-group"
                    style="display: flex; gap: 5px; margin-top: 6px"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      v-model="FirstName"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="LastName"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City you live in"
                      v-model="City"
                    />
                  </div>
                  <div class="form-group" style="margin-top: 6px">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      v-model="Email"
                    />
                    <div v-if="emailError" class="text-danger">
                      {{ emailError }}
                    </div>
                  </div>

                  <div
                    v-if="localInquiryType === 'Used Car Sale'"
                    class="form-group"
                  >
                    <div style="display: flex; gap: 5px; margin-top: 6px">
                      <input
                        type="file"
                        class="form-control"
                        id="images"
                        multiple
                        @change="handleFileUpload"
                        ref="fileInput"
                        accept=".jpg, .jpeg"
                      />
                      <button
                        v-if="selectedFiles.length >= 1"
                        class="view-btn"
                        @click="viewImages"
                      >
                        View
                      </button>
                    </div>
                    <label
                      style="color: #ffffff; margin-left: 15px"
                      for="images"
                      >Upload Images (JPG only, max 5MB each)</label
                    >
                    <div v-if="uploadError" class="text-danger">
                      {{ uploadError }}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    style="display: flex; gap: 5px; margin-top: 6px"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Number"
                      v-model="PhoneNo"
                      @input="validatePhoneNo"
                      :class="{ 'is-valid': isVerified }"
                    />
                    <span
                      v-if="isVerified"
                      class="tick-icon"
                      style="
                        position: absolute;
                        right: 110px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: none;
                      "
                      >✔️</span
                    >
                    <!-- <button
                      class="verify-btn"
                      v-if="showVerifyButton"
                      @click="verifyMagicLink"
                      :disabled="isVerified"
                    >
                      Verify
                    </button> -->
                    <button
                      class="verify-btn"
                      v-if="!otpVerified"
                      @click="verifyMagicLink"
                      :disabled="otpVerified"
                    >
                      Verify
                    </button>
                  </div>

                  <!-- <div class="btn-section">
                     <button
                      type="button"
                      style="color: #ffffff"
                      class="btn submit-btn"
                      :class="{ 'blurred-button': !isVerified }"
                      @mouseover="showMessage"
                      @mouseleave="hideMessage"
                      @click="submitQuery"
                    >
                      Submit
                    </button> 
                     <div
                      v-if="showTooltip && !isVerified"
                      class="tooltip-message"
                      :style="{
                        top: tooltipPositionTop + 'px',
                        right: tooltipPositionLeft + 'px',
                      }"
                    >
                      Please verify your watsapp number.
                    </div> 
                  </div>  -->
                  <!-- v-if="showSubmitButton" -->

                  <div v-if="showOtpModal" class="modal-mask">
                    <div class="modal-wrapper">
                      <div class="modal-container">
                        <span class="close" @click="showOtpModal = false"
                          >&times;</span
                        >
                        <div>
                          <h3 style="color: #000000; text-align: center">
                            OTP Verification
                          </h3>
                        </div>
                        <div>
                          <p v-if="modalMessage">{{ modalMessage }}</p>

                          <div
                            class="otp-inputs"
                            style="margin-top: 10px; display: flex; gap: 15px"
                          >
                            <input
                              v-for="(input, index) in otpInputs"
                              :key="index"
                              type="text"
                              v-model="otpInputs[index]"
                              maxlength="1"
                              class="otp-input"
                              :style="inputStyle(index)"
                              @input="handleInput(index)"
                              @keydown="handleKeydown(index)"
                              ref="otpInput"
                            />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="resend-btn"
                            :disabled="timer > 0"
                            @click="resendOtp"
                          >
                            {{
                              timer > 0
                                ? `Resend Code in ${timer}s`
                                : "Resend Code"
                            }}
                          </button>
                          <button class="verify-btn" @click="verifyOtp">
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="showModals" class="modal-mask">
                    <div class="modal-wrapper">
                      <div class="modal-container">
                        <div class="modal-header">
                          <h3 style="color: #348aff">Uploaded Images</h3>
                          <span class="close" @click="showModals = false"
                            >&times;</span
                          >
                        </div>
                        <div class="modal-body">
                          <div class="modal-images-container">
                            <img
                              :src="images[currentImageIndex]"
                              alt="Uploaded Image"
                              class="modal-image"
                            />
                          </div>
                        </div>
                        <div class="modal-controls">
                          <button
                            @click="prevImage"
                            :disabled="currentImageIndex === 0"
                            class="prev-btn"
                          >
                            Previous
                          </button>
                          <span
                            >{{ currentImageIndex + 1 }} /
                            {{ images.length }}</span
                          >
                          <button
                            @click="nextImage"
                            :disabled="currentImageIndex === images.length - 1"
                            class="next-btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
export default {
  name: "Inquiryformmodal",
  props: {
    InquiryType: String,
    showTitle: Boolean,
    showModal: Boolean,
    onClose: Function,
    vehiclesId: Number,
    // sellerName: String,
    // sellerInquiryNo: String,
    // makeName: String,
    // regNoo: String,
    // inquiryNoo: String,
  },
  data() {
    return {
      selectedFiles: [],
      images: [],
      uploadError: "",
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNo: "",
      City: "",
      showModals: false,
      currentImageIndex: 0,
      localInquiryType: "",
      Date: new Date(),
      otpInputs: ["", "", "", ""],
      otpCode: [],
      timer: 60,
      timerInterval: null,
      showOtpModal: false,
      modalMessage: "",
      isVerified: false, // Track if the phone number is verified
      hardcodedOtp: "1234",
      requestId: null,
      showSubmitButton: false,
      showVerifyButton: true,
      showTooltip: false,
      tooltipPositionTop: 1,
      tooltipPositionLeft: 1,
      // inqFormSellerName: "",
      // inqFormInquiryNo: "",
      // inqFormVehicleName: "",
      // inqFormVehicleRegNo: "",
      // inquiryDescription:null,
      VUE_APP_ROOT_API: process.env.VUE_APP_ROOT_API,
      otpVerified: false,
    };
  },
  mounted() {
    this.localInquiryType = this.InquiryType;
    // this.inqFormSellerName = this.sellerName;
    // this.inqFormInquiryNo = this.inquiryNoo;
    // this.inqFormVehicleName = this.makeName;
    // this.inqFormVehicleRegNo = this.regNoo;

    // console.log("Mounted - vehiclesId:----", this.vehiclesId);
    // console.log("Mounted - Seller Name:----", this.sellerName);
    // console.log("Mounted - Inquiry No :----", this.inquiryNoo);
    // console.log("Mounted - Vehicle Name:----", this.makeName);

    // console.log("Mounted - Vehicle Registration No :----", this.regNoo);
    // console.log("process.env_API-=================",this.VUE_APP_ROOT_API);
  },

  methods: {
    verifyMagicLink() {
      if (this.localInquiryType === "") {
        alert("Please select query.");
        return false;
      }
      if (this.FirstName === "" || this.LastName === "") {
        alert("Please enter your full name.");
        return false;
      }

      if (this.Email != "") {
        if (!this.validateEmail()) {
          alert("Please enter a valid email address with a domain name.");
          return false;
        }
      }
      if (this.PhoneNo === "") {
        alert("Please enter mobile number.");
        return false;
      }

      if (
        this.selectedFiles.length < 2 &&
        this.localInquiryType === "Used Car Sale"
      ) {
        alert("Please upload at least two images.");
        return false;
      }
      if (!this.PhoneNo || this.PhoneNo.length < 10) {
        alert("Please enter a valid phone number."); // Notify user if invalid
        return;
      }
      if (
        this.localInquiryType === "Used Car Sale" &&
        this.selectedFiles.length < 2
      ) {
        alert("Please upload at least two images.");
        return false;
      }

      if (!this.PhoneNo || this.PhoneNo.length < 10) {
        alert("Please enter a valid phone number."); // Notify user if invalid
        return;
      }

      this.otpInputs = Array(4).fill("");
      this.enteredOtp = "";
      this.showOtpModal = true;

      let data = {
        phoneNumber: "+91" + this.PhoneNo,
        expiry: 120,
        otpLength: 4,
        channels: ["WHATSAPP"],
      };
      this.startTimer();
      axios
        .post("https://auth.otpless.app/auth/v1/initiate/otp", data, {
          headers: {
            "Content-Type": "application/json",
            clientId: "QH19JS2GMJL06ABDLY24I1VHE1ZJZIAK",
            clientSecret: "psa9q89xk27p6y7vx5ru4ntrm6yb2j3t",
          },
        })
        .then((res) => {
          // this.isVerified = true;
          this.showTooltip = false;
          if (res.data.requestId) {
            this.requestId = res.data.requestId;
            this.showVerifyButton = false;

            Toastify({
              text: "OTP sent successfully.",
              duration: 3000, // Customize duration as needed
              close: true,
              gravity: "top", // Position on the screen
              position: "right", // Position on the screen
              backgroundColor: "#6ebe44", // Success color
            }).showToast();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          this.resetForm();
          console.log("Error:", err);
          console.log("Error:", err.response ? err.response.data : err);
          alert("There was an error submitting the form.");
        });
      this.enteredOtp = "";
    },

    handleInput(index) {
      if (
        this.otpInputs[index].length === 1 &&
        index < this.otpInputs.length - 1
      ) {
        this.$refs.otpInput[index + 1].focus(); // Focus next input
      }
    },
    handleKeydown(index) {
      if (
        event.key === "Backspace" &&
        index > 0 &&
        this.otpInputs[index] === ""
      ) {
        this.$refs.otpInput[index - 1].focus(); // Focus previous input
      }
    },
    startTimer() {
      this.timer = 60; // Reset timer to 60 seconds
      clearInterval(this.timerInterval); // Clear any existing timer

      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    resendOtp() {
      this.verifyMagicLink(); // Resend OTP
    },
    verifyOtp() {
      const enteredOtp = this.otpInputs.join("");
      this.otpVerified = true;
      console.log("enteredotp----", enteredOtp);

      axios
        .post(
          "https://auth.otpless.app/auth/v1/verify/otp",
          {
            requestId: this.requestId,
            otp: enteredOtp,
          },
          {
            headers: {
              "Content-Type": "application/json",
              clientId: "QH19JS2GMJL06ABDLY24I1VHE1ZJZIAK",
              clientSecret: "psa9q89xk27p6y7vx5ru4ntrm6yb2j3t",
            },
          }
        )
        .then((res) => {
          // this.isVerified = true;
          this.showTooltip = false;
          if (res.data.isOTPVerified) {
            this.isVerified = res.data.isOTPVerified;
            this.isVerified = true;
            Toastify({
              text: res.data.message,
              duration: 3000,
              backgroundColor: "#6ebe44",
              close: true,
            }).showToast();
            this.showOtpModal = false;
            this.submitQuery();
            this.enteredOtp = "";
            //console.log("verifyotp----",this.enteredOtp);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          this.resetForm();
          this.enteredOtp = "";
          this.onClose(false);
          console.log("Error:", err);
          alert("There was an error submitting the form.");
        });
      this.enteredOtp = "";
    },

    inputStyle(index) {
      return this.otpInputs[index] ? { outline: "2px solid #6ebe44" } : {};
    },

    focusNext(index) {
      if (this.otpInputs[index]) {
        const nextInput = this.$refs[`otpInput${index + 1}`];
        if (nextInput) {
          nextInput.focus();
        }
      }
    },

    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.Email);
    },

    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      console.log("Uploaded files:", files);
      this.uploadError = "";

      if (this.localInquiryType === "Used Car Sale") {
        this.selectedFiles = files;
        this.images = [];

        files.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push(e.target.result);
          };
          reader.readAsDataURL(file);
        });
      }
    },
    viewImages() {
      if (this.selectedFiles.length >= 1) {
        this.currentImageIndex = 0;
        this.showModals = true;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex++;
      }
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    validatePhoneNo() {
      this.PhoneNo = this.PhoneNo.replace(/\D/g, "");
      if (this.PhoneNo.length > 10) {
        this.PhoneNo = this.PhoneNo.slice(0, 10);
      }
    },

    submitQuery() {
      if (this.isVerified) {
        // if (this.localInquiryType === "") {
        //   alert("Please select query.");
        //   return false;
        // }
        // if (this.FirstName === "" || this.LastName === "") {
        //   alert("Please enter your full name.");
        //   return false;
        // }
        // if (this.Email === "") {
        //   alert("Please enter your email id.");
        //   return false;
        // }
        // if (!this.validateEmail()) {
        //   alert("Please enter a valid email address with a domain name.");
        //   return false;
        // }
        // if (this.Email != "") {
        //   if (!this.validateEmail()) {
        //     alert("Please enter a valid email address with a domain name.");
        //     return false;
        //   }
        // }
        // if (this.PhoneNo === "") {
        //   alert("Please enter mobile number.");
        //   return false;
        // }
        // if (this.City === "") {
        //   alert("Please enter city.");
        //   return false;
        // }
        if (
          this.selectedFiles.length < 2 &&
          this.localInquiryType === "Used Car Sale"
        ) {
          alert("Please upload at least two images.");
          return false;
        }

        const formData = new FormData();
        if (this.localInquiryType === "Used Car Sale") {
          this.selectedFiles.forEach((file) => {
            formData.append("files", file);
            console.log(
              `File Name: ${file.name}, File Size: ${file.size} bytes`
            );
          });
        }
        // const inquiryDescription = `Name of Seller : ${this.inqFormSellerName || "Unknown"}
        // Inquiry No of Seller : ${this.inqFormInquiryNo || "Unknown"}
        //   Vehicle Name : ${this.inqFormVehicleName || "Unknown"}
        //  Vehicle Registration : ${this.inqFormVehicleRegNo || "Unknown"}`;
        formData.append(
          "inquiryData",
          JSON.stringify({
            firstName: this.FirstName,
            lastName: this.LastName,
            emailAddress: this.Email,
            inquiryType: this.localInquiryType,
            inquiryStatus: `Open`,
            contactNumber: `${this.PhoneNo}`,
            city: this.City,
            vehiclesId: this.vehiclesId,
            // inquiryDescription: inquiryDescription,
            // inquiryDescription: `Name of Seller : ${this.inqFormSellerName}
            // Inquiry No of Seller : ${this.inqFormInquiryNo}
            // Vehicle Name : ${this.inqFormVehicleName}
            // Vehicle Registration : ${this.inqFormVehicleRegNo}`,
          })
        );
        // for (const [key, value] of formData.entries()) {
        //   console.log("dfsdfsdfsdfsdf    ", `${key}:`, value);

        // }
        axios
          .post(`${this.VUE_APP_ROOT_API}/add-inquiry1`, formData)
          .then((res) => {
            this.baseResponse = res.data;
            if (
              this.baseResponse === "" ||
              this.baseResponse.code === "ADDED"
            ) {
              //this.addInquiryNotes();
              alert(
                "Inquiry Request submitted successfully. Our Support Team Will Contact You Soon!"
              );

              this.resetForm();
              this.onClose(false);
              this.enteredOtp = "";
            } else {
              this.resetForm();
              alert("Some Thing went wrong! please try later.");
              this.enteredOtp = "";
            }
          })
          .catch((err) => {
            this.resetForm();
            this.enteredOtp = "";
            console.log("Error:", err);
            console.log("Error:", err.response ? err.response.data : err);
            alert("Some Thing went wrong! please try later.");
          });
          this.otpVerified = false;
      }
    },
    showMessage() {
      this.showTooltip = true;

      // Position the tooltip near the button

      this.tooltipPositionTop = 1;
      this.tooltipPositionLeft = 1;
    },
    hideMessage() {
      this.showTooltip = false;
    },

    resetForm() {
      //  this.vehiclesId = "",
      //  this.inqFormSellerName = "",
      //  this.inqFormInquiryNo = "",
      //  this.inqFormVehicleName = "",
      //  this.inqFormVehicleRegNo = "",
      //   this.inquiryDescription = "",

      this.isVerified = false;
      this.showTooltip = true;
      this.showVerifyButton = true;
      this.FirstName = "";
      this.LastName = "";
      this.Email = "";
      this.PhoneNo = "";
      this.City = "";
      this.images = [];
      this.showModals = false;
      this.selectedFiles = [];
      if (this.localInquiryType === "Used Car Sale") {
        this.$refs.fileInput.value = "";
      }
    },
    //   watch: {

    //   sellerName(newVal) {
    //     this.inqFormSellerName = newVal;
    //   // console.log("watch ",this.inqFormSellerName);

    //   },
    //   inquiryNoo(newVal) {
    //     this.inqFormInquiryNo = newVal;
    //   },
    //   makeName(newVal) {
    //     this.inqFormVehicleName = newVal;
    //   },
    //   regNoo(newVal) {
    //     this.inqFormVehicleRegNo = newVal;
    //   },
    // },

    // addInquiryNotes() {
    //   console.log("addInquiryNotes called");
    //   console.log("sellerName:----------", this.inqFormSellerName);
    //   console.log("inquiryNoo:----", this.inqFormInquiryNo);
    //   console.log("makeName:----", this.inqFormVehicleName);
    //   console.log("regNoo:", this.inqFormVehicleRegNo);
    //   const formData = new FormData();
    //   formData.append(
    //     "inquiryNotes",
    //     JSON.stringify({
    //       inquiryNotes: `Name of Seller : ${"Rohit Kumar"}
    //   Inquiry No of Seller : ${"UCS6554545fg"}
    //   Vehicle Name : ${"Audi"}
    //   Vehicle Registration : ${"PB26BG8745"}`,
    //     })
    //   );

    //   // Log formData content
    //   formData.forEach((value, key) => {
    //     console.log(`${key}: ${value}`);
    //   });
    //   axios
    //     .post(`${this.VUE_APP_ROOT_API}/add-inquiry-Notes-website`, formData)
    //     .then((response) => {
    //       console.log("Inquiry notes sent successfully:", response.data);
    //     })
    //     .catch((error) => {
    //       console.error("Error sending inquiry notes:", error.response || error);
    //     });
    // },

    addInquiryNotes() {
      // console.log("this.inqFormInquiryNo",this.inqFormInquiryNo)
      const formData = new FormData();
      formData.append(
        "inquiryNotes",
        JSON.stringify({
          inquiryNotes: `Name of Seller : ${this.inqFormSellerName}
       Inquiry No of Seller : ${this.inqFormInquiryNo}
       Vehicle Name : ${this.inqFormVehicleName}
       Vehicle Registration : ${this.inqFormVehicleRegNo}`,
        })
      );

      // Log formData content
      // formData.forEach((value, key) => {
      //     console.log(`${key}: ${value}`);
      // });

      axios
        .post(`${this.VUE_APP_ROOT_API}/add-inquiry-Notes-website`, formData)
        .then((response) => {
          console.log("Inquiry notes sent successfully:", response.data);
        })
        .catch((error) => {
          console.error(
            "Error sending inquiry notes:",
            error.response || error
          );
        });
    },

    //     addInquiryNotes() {
    //   // Ensure you're using FormData and appending the raw data correctly
    //   const formData = new FormData();

    //   // Prepare the inquiry data
    //   const inquiryData = {
    //     inquiryNotes: `Name of Seller: ${this.inqFormSellerName}
    //     Inquiry No of Seller: ${this.inqFormInquiryNo}
    //     Vehicle Name: ${this.inqFormVehicleName}
    //     Vehicle Registration: ${this.inqFormVehicleRegNo}`
    //   };

    //   // Append the stringified data to FormData
    //   formData.append("inquiryData", JSON.stringify(inquiryData));

    //   // Iterate over FormData and log key-value pairs
    // for (const [key, value] of formData.entries()) {
    //   console.log("FormData entry:", `${key}:`, value);
    // }

    //   // Send the data with Axios
    //   axios
    //     .post(`${this.VUE_APP_ROOT_API}/add-inquiry-Notes-website`, formData)
    //     .then((response) => {
    //       console.log("Inquiry notes sent successfully:", response.data);
    //     })
    //     .catch((error) => {
    //       console.error("Error sending inquiry notes:", error.response || error);
    //     });
    // }
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 650px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #000000;
}
.modal-header {
  text-align: center; /* Center the text in the header */
}
.modal-content {
  background: #348aff;

  border-radius: 15px;
  width: 550px;
  margin: auto;
  margin-top: -10px;
}

.modal-title {
  font-size: 22px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 8px;
}

.view-btn {
  background-color: #6ebe44;
  border: none;
  color: white;
  padding: 6px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: "#ffffff";
}

button.close {
  border-radius: 10px !important;
}

.blurred-button {
  opacity: 2;
  cursor: not-allowed;
}

.tooltip-message {
  background-color: #ffcc00;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  font-size: 14px;
  z-index: 10;
  visibility: visible;
  white-space: nowrap; /* Prevent text wrapping */
  max-width: 300px; /* Adjust max width as needed */
  word-wrap: break-word; /* Ensure the text breaks properly if it exceeds max width */
}

.verify-btn {
  background-color: #6ebe44;
  border: none;
  color: white;
  padding: 6px 20px;
  border-radius: 20px !important;
  cursor: pointer;
  transition: background-color 0.3s;
}
</style>
