<template>
  <section class="secarch-your-car">
    <div class="container">
      <div class="row inner-search-sec">
        <h2>Search your favorite car</h2>
        <div class="inner-sec-form" name="homesearch">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-2">
                <div class="togglesec">
                  <div class="dropdown menuselection">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i class="fa fa-bars" aria-hidden="true"></i> Any
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_make"
                        v-model="search_make"
                        v-on:change="getModels($event)"
                      >
                        <option value="">Make</option>
                        <option
                          v-for="(brand, key) in brands"
                          :key="key"
                          :value="brand"
                        >
                          {{ brand.toUpperCase() }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_model"
                        v-model="search_model"
                      >
                        <option value="">Model</option>
                        <option
                          v-for="(model, key) in models"
                          :key="key"
                          :value="model.modelvalue"
                        >
                          {{ model.modelname }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_fuel_type"
                        v-model="search_fuel_type"
                      >
                        <option value="">Fuel Type</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Electric">Electric</option>
                        <option value="LPG">LPG</option>
                        <option value="CNG">CNG</option>
                        <option value="Petrol">Petrol</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <select
                    class="form-control"
                    id="search_transmission"
                    v-model="search_transmission"
                  >
                    <option value="">Transmission</option>
                    <option value="Manual">Manual</option>
                    <option value="Automatic">Automatic</option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <h3>&#8377;{{ search_price }}</h3>
                <div class="form-group">
                  <input
                    type="range"
                    min="1"
                    max="2000000"
                    class="form-control-range"
                    id="myRange"
                    v-model="search_price"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="searchbtn">
                  <a href="javascript:void(0)" v-on:click="searchQuery"
                    >Search</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loading message while cars data is being fetched -->
        <div v-if="!cars.length">Loading data...</div>

        <!-- Cars cards display -->
        <div class="container" v-if="cars.length > 0">
          <div class="card-deck row">
            <div v-for="(car, index) in displayedCars" :key="index" class="col-xs-12 col-sm-6 col-md-4">
              <!-- Card -->
              <div class="card mb-4"  >
                <!-- Image scroll container -->
                <div class="image-scroll-container" style="z-index:111"  @mouseenter="hover = true"   @mouseleave="hover = false" @click="openModal(car)">
                    <button class="scroll-btn left" @click.stop="openModal(car)" @click="scrollImage(index,left)">
                  <i class="fa fa-chevron-left"></i>
                    </button>
                  <div class="image-carousel  cardimage"  :class="{ visible: hover }"  :style="{ transform: `translateX(-${car.currentImageIndex * 100}%)` }">
                    <img
                      v-for="(image, imgIndex) in car.images"
                      :key="imgIndex"
                      :src="image"
                      alt="Car image"
                      class="card-img-top"
                    />
                  </div>
                  <button class="scroll-btn right"   :class="{ visible: hover }"  @click.stop="openModal(car)" @click="scrollImage(index , right)">
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>

                <!-- Card content -->
                <div class="card-body"  >
                   <div class="d-flex justify-content-between cardTitle">
                   <h3 class="card-title" 
                        style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                         {{
                              car.vehManuFacDate 
                            ? car.vehManuFacDate + ' - ' + (car.makeName || 'Make not available')
                            : (car.makeName || 'Make not available')
                         }}
                        {{ car.modelVariant || '' }}
                        </h3>
                 
                   </div>
                    <div class="d-flex ">
                  <!-- <h4 class="card-title">{{ car.makeName || 'Make not available' }}</h4> -->
                 
                   <p v-if="car.kms != undefined" class="card-text">{{car.kms == undefined ? '' :  car.kms + 'km' }} · &nbsp;</p>
                     <p v-if="car.fuelType != undefined" class="card-text">{{car.fuelType == undefined ? '' :  car.fuelType  }}  &nbsp;</p>
                   </div>
                   
                
                   <div style="display:flex; justify-content:space-between; margin-top:0px;">
                   <!-- 
                    -->

                   <div>
                    <p class="card-text carPrice">₹{{ car.vehicleValue ? car.vehicleValue.toLocaleString() : 'Not available' }}</p>
                   </div>
                     <div>
                      <button
                    type="button"
                    style="color: #ffffff"
                    class="btn submit-btn"
                    
                    @click="onBookNow(car.vehiclesId)"
                  >
      
                    Book Now
                  </button>
                  
                     </div>
                     <Inquiryformmodal   v-if="showModal" :vehiclesId="selectedVehiclesId"  @close="showModal = false" />
                  </div>
                      <div class="d-flex justify-content-between">
                      <div>
                       <!-- <p class="card-text">Vehicle Buy Date: {{ car.vehicleBuyDate || 'Not available' }}</p> -->
                       </div>
                        <!--  <p class="card-text">Vehicle Value: ₹{{ car.vehicleValue ? car.vehicleValue.toLocaleString() : 'Not available' }}</p> -->
                      <!-- <div> <p class="card-text">KMS: {{ car.kms || 'Not available' }}</p></div>   -->
                   </div>
                 </div>
                </div>
            </div>
          </div>
          

     
               <div style="display:flex; justify-content:center;">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="Math.ceil(cars.length / cardsPerPage)"  @change="fetchData" use-router></b-pagination-nav>
          </div>

        

     
          
             
       <!-- Car Modal (Popup) -->
        <div v-if="this.modalvalue == 1 && isModalVisible" class="modal" @click.self="closeModal">
          <div class="modal-contents">
            <span class="close-btn" @click="closeModal">&times;</span>
            <div class="image-scroll-container">
              <button class="scroll-btn left" @click="scrollImageInModal(-1)">
                <i class="fa fa-chevron-left"></i>
              </button>
              <div class="image-carousel  modalimage" :style="{ transform: `translateX(-${modalCar.currentImageIndex * 100}%)` }">
                <img
                  v-for="(image, imgIndex) in modalCar.images"
                  :key="imgIndex"
                  :src="image"
                  alt="Car image"
                  class="card-img"
                />
              </div>
              <button class="scroll-btn right" @click="scrollImageInModal(1)">
                <i class="fa fa-chevron-right"></i>
              </button>
            </div>

            <div class="modal-body ">
              <h4 class="card-title" 
                  style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-weight:600;">
                 {{
                      (modalCar.vehManuFacDate ? modalCar.vehManuFacDate + ' - ' : '') +
                      (modalCar.makeName || '') +
                      (modalCar.modelVariant ? ' ' + modalCar.modelVariant : '')
                 }}
              </h4>

              <div class="d-flex ">
               <p  class="card-text">{{modalCar.kms == undefined ? '' :  modalCar.kms + 'km' }}  </p>
               <p v-if="modalCar.kms !=undefined">&nbsp;·&nbsp;</p>
               <p v-if="modalCar.fuelType != undefined" class="card-text">{{modalCar.fuelType == undefined ? '' :  modalCar.fuelType  }}  &nbsp;</p> 
                     </div>

          <!--   <p>KMS: {{  modalCar.kms == undefined ? '' :  modalCar.kms + 'km' }}</p> -->
               
        

        

            <div style="display:flex; justify-content:space-between;">
            <div>
                <p class="card-text carPrice">₹{{ modalCar.vehicleValue ? modalCar.vehicleValue.toLocaleString() : 'Not available' }}</p>
            </div>

            <div>
             <button
                    type="button"
                    style="color: #ffffff"
                    class="btn submit-btn"
                    @click="onBookNow(modalCar.vehiclesId)"
                  >
                    Book Now
                  </button>
                 
            </div>
            </div>

                <!-- <p>Vehicle Value: ₹{{ modalCar.vehicleValue ? modalCar.vehicleValue.toLocaleString() : 'Not available' }}</p> -->
            </div>
          </div>
         </div>
        </div>
          
          
      </div>

   
        <Inquiryformmodal
        :vehiclesId="selectedVehiclesId"  @close="showModal = false"
         InquiryType="Used Car Purchase"
        :showTitle="false"
        :showModal="showModal"
        :onClose="onClose"
      
      />


    
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Inquiryformmodal from '@/components/common/Inquiryformmodal.vue';
const modeldata = require("../makemodels.json");



export default {
  name: "Homesearch",
  
  components: {
    Inquiryformmodal, // Register the Inquiryformmodal component
  },
 
  data() {
    return {
      showModal: false, 
      search_make: "",
      search_model: "",
      search_fuel_type: "",
      search_transmission: "",
      search_price: 2000000,
      brands: [],
      models: [],
      left:"left",
      right:"right",
      cars: [
        {
          images: [
           "https://picsum.photos/1024/480/?image=55",
            "https://picsum.photos/1024/480/?image=55",
            "https://picsum.photos/1024/480/?image=55",
          ],
          currentImageIndex: 0
        }
      ],
      imagesaaa: [ ],
      currentImageIndex: 2,
      displayedCars: [],
      currentPage: 1, // Current page number
      cardsPerPage: 6, // Cards to display per page
      isModalVisible: false,
      modalvalue:0,
      modalCar: {},
     

      VUE_APP_ROOT_API : process.env.VUE_APP_ROOT_API,
      

     
      
  };
},

 methods: {

    onBookNow(vehiclesId) {
 
      this.showModal = true;
      this.isModalVisible = false; 
      this.selectedVehiclesId =vehiclesId;
     // console.log("Seller Vehicle Id 3 : ",this.selectedVehiclesId);
      
      // this.sellerNames = sellerName;
      // console.log("Seller Name 3: ",this.sellerNames);
      
      // this.sellerInquiryNo = inquiryNoo;
      // console.log("Seller Inquiry No 3 : ",this.sellerInquiryNo);
      

      // this.vehicleName = makeName+" "+modelVariant;
      // console.log("Vehicle Name 3 :  ",this.vehicleName);

      // this.sellerRegistrationNo = regNoo;
      // console.log("Registration No 3 : ",this.sellerRegistrationNo);
      

    
      
      // console.log("Seller Name ----",this.sellerNames);
      // console.log("Inquiry No ----",this.sellerInquiryNo);
      // console.log("Registration No  ----",this.sellerRegistrationNo);
      // console.log("Vehicle Name----",this.vehicleName);
      
      
      
    } , // Function to handle closing the modal


    onClose() {
      this.showModal = false; // Hide the modal
    },
 
 linkGen(pageNum) {
  this.currentPage = pageNum; // Set the current page
  this.fetchData(pageNum);    // Fetch data for the selected page
  return `?page=${pageNum}`;
},

     openModal(car) {
    //  console.log("car------------",car);
      this.modalvalue = 1;
      // console.log("Modal triggered for car:", car);  // Check if this logs correctly
      this.modalCar = car; 
       // Set the car data to display in the modal
     
      this.isModalVisible = true;  // Show the modal
      
       
    },
      
 closeModal() {
    // console.log("Closing modal...");
    this.isModalVisible = false;  
    this.modalCar = {}; 
  },

 async fetchData(page = 1) {
 

    try {
      const params = {};

      if (this.search_make !== "") params.makeId = this.search_make;
      if (this.search_fuel_type !== "") params.fuelType = this.search_fuel_type;
      if (this.search_transmission !== "") params.transType = this.search_transmission;
      if (this.search_price) params.valueEnd = this.search_price;
      if (this.search_model !=="") params.model = this.search_model;

      
      const response = await axios.post(`${this.VUE_APP_ROOT_API}/fetch-inquiry-document-for-website`,params);
     
      this.cars = response.data.map(car => {
      
      const imagePaths = car.fileName.split(",").map(file => 
         `${this.VUE_APP_ROOT_API}/view-document/${file.toString().replace("/", "~").trim()}/${car.locationId}`
      
         );
      
      
      return {
        ...car,
        images: imagePaths, // Set the parsed image URLs here
        currentImageIndex: 0,
      };

       
    });
    // console.log('Processed cars------:', this.cars);
    // console.log("Seller Name : ",this.car.sellerName);
    // console.log("Seller Inquiry NO : ",this.car.inquiryNoo);
    // console.log("Vehicle Name : ",this.car.makeName," ",this.car.modelVariant);
    // console.log("Registration No : ",this.car.regNoo);
    
    
    this.carss = response.data.map(car => {
     let carVehId = car.vehiclesId;
  
     return {
      ...car, // Retain the original car object properties
      carVehicleIds :carVehId, // Add carVehId to the mapped object
      };
     });
    
     
     for (const car of this.cars) {
      this.imagesaaa.push(...car.images); // Spread operator to merge arrays
     }

 
      const startIndex = (page - 1) * this.cardsPerPage;
      const endIndex = startIndex + this.cardsPerPage;
     

      // Update displayedCars based on the current page
      this.displayedCars = this.cars.slice(startIndex, endIndex);
    } catch (error) {
    console.error("Error fetching data:", error);
   }
  },

scrollImage(index, direction) {
  this.modalvalue = 0; // Reset modal value (if needed)
  const car = this.displayedCars[index]; // Get the car object based on the index
  const totalImages = car.images.length; // Use car.images.length to get the total images for that specific car
 

  // Update currentImageIndex based on the direction
  if (direction === 'left') {
    // console.log("car.currentImageIndex", car.currentImageIndex);
    // Scroll left: Decrease the index and wrap around if needed
    car.currentImageIndex = (car.currentImageIndex - 1 + totalImages) % totalImages;
  } else if (direction === 'right') {
    // Scroll right: Increase the index and wrap around if needed
    car.currentImageIndex = (car.currentImageIndex + 1) % totalImages;
  }
},

scrollImageInModal(direction) {
  // console.log("rightImage",this.modalCar.currentImageIndex)
  let totalImages = this.modalCar.images.length
  let newIndex = this.modalCar.currentImageIndex + direction;
  if (newIndex < 0) {
    newIndex = totalImages -1; // Loop back to the last image
  } else if(newIndex == totalImages ){
  newIndex=0;
  }
  this.modalCar.currentImageIndex = newIndex;
},

  
    
   searchQuery() {
    //  var data = {
    //    makeId: this.search_make !== "" ? this.search_make : null,
    //    fuelType: this.search_fuel_type !== "" ? this.search_fuel_type : null,
    //    transType: this.search_transmission !== "" ? this.search_transmission : null,

     //   valueEnd: this.search_price,
    //    model: this.search_model,
    //  };
      //sessionStorage.setItem("search_payload", JSON.stringify(data));
     // this.$router.push("/search");
      this.fetchData();
    },

    getModels(e) {
      var brand = e.target.value;
      this.models = [];
      modeldata.forEach((make) => {
        if (brand === make.brand) {
          this.models.push(make);
        }
      });
    },
  },

  mounted() {
    
    modeldata.forEach((make) => {
      if (!this.brands.includes(make.brand)) {
        this.brands.push(make.brand);
      }
    });
    this.fetchData();
    this.fetchedData = this.response.data;
   
    
    //console.log("api---homesearch=====",this.VUE_APP_ROOT_API);
 
    
  },
};
</script>

<style>
  /* Container styling for the car search section */

    .container {

    flex-wrap: wrap;
   justify-content: center;
  
  }
  
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
    margin-top: 38px;

}

  .cards {
  flex: 1 1 calc(33.33% - 32px);
  box-sizing: border-box;
  margin: 16px;
  background-color: #f8f8f8; 
  width:100%;
  max-width:250px;
  max-height:250px;
  }

  .card-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
  }


   /* Card info section styling */
  .card-info {
    padding: 16px;
  }

  .card-title {
    font-size: 1.25em;
   color: #333;
  }

  .card-intro {
    color: #666;
    font-size: 0.9em;
    line-height: 1.5;
  }


   /* Responsive design for different screen sizes */
  @media (max-width: 768px) {
    .cards {
      flex: 1 1 calc(50% - 32px);
    }
  }

  @media (max-width: 480px) {
    .cards {
      flex: 1 1 100%;
    }
  }

    /* Placeholder image styling */
   .card-image {
    height: 200px;
    background-color: #ccc;
    background-image: url('https://via.placeholder.com/150');
    background-size: cover;
    background-position: center;
  }
    
    .card{
    margin: 0.5% 0%;
    }

.card-body{
  margin: 0% 0% 0% 3%;
  padding:20px;
}

.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.image-scroll-container {
  position: relative; 
  overflow: hidden;
}

 .image-scroll-container button{
    display:none;
  }
   
  .image-scroll-container:hover button{
    display:block;
  }

  .image-carousel {
    display: flex;
    transition: transform 0.3s ease;
  }
  .scroll-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;  
}
 .scroll-btn.left {
  left: 10px; 
}

.scroll-btn.right {
  right: 10px;  
}

.card-img {
  width: 100%;
  height: auto;  
  object-fit: fill;  
  border-radius:8px 8px;
 
}

.card-img-top {
  width: 100%;
  object-fit: fill;  


}

.cardimage{
  height:200px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-contents {
  width: 100%; 
  max-width: 1200px; 
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}


.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
}
.modalimage{
 width:100%;
 height:500px;
}

.inner-search-sec h2 {
    font-size: 20px;
    color: #313131;
     padding-bottom: 0px; 
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif !important;
}

.form-control {
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 7px 10px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s, box-shadow 0.3s;
}
</style>
